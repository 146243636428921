import { Text } from "@chakra-ui/react";
import React, { useContext, useEffect } from "react";
import { ApiContext, UserContext } from "../components/Root";

function LogoutPage() {
  const user = useContext(UserContext);
  const api = useContext(ApiContext);
  useEffect(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem("token");
      window.localStorage.removeItem("user");
    }
    user.signOut();
    api.resetClient();
  }, [user, api]);

  return <Text>You have logged out!</Text>;
}

export default LogoutPage;
